:root {
    --clr-black: #333333;
}

.nav-text-link a {
    color: #333 !important;
}


.lefeuve-nav-bar .nav-text {
    color: var(--clr-black) !important;
    font-weight: 600;
}

.text__header {
    position: absolute;
    top: 30%;
    left: 50%;
    z-index: 9999;
    padding: 15px;
    border-radius: 5px;
    color: var(--clr-black);
    transform: translate(-50%, -30%);
    background-color: rgb(255, 255, 255, 0.5);
}

.btn__content {
    position: absolute;
    top: 62%;
    left: 50%;
    z-index: 9999;
    border-radius: 5px;
    color: var(--clr-black);
    transform: translate(-50%, -50%);
    display: flex;
    align-items: flex-end;
}

.btn__content .header-whatsapp-content {
    background-color: #25d366;
    padding: 6px 57px 6px 10px;
    border-radius: 10px;
    color: #fff;
    position: relative;
}


.btn__content a {
    text-decoration: none;
    color: black;
    position: relative;
}

.btn__content .whatsapp-ico {
    font-size: 4rem;
    background-color: #25d366;
    border-radius: 50%;
    padding: 5px;
    color: #fff;
    position: absolute;
    top: -15px;
    right: -10px;
}

.header-whatsapp-content .whatsapp-msg {
    border: 1px solid;
    padding: 2px 8px;
    border-radius: 10px;
}

.logo__img {
    width: 90px;
}

.btn__paternariat {
    border: 1px solid #a68c69;
    border-radius: 8px;
}

.btn .quantity-product {
    position: absolute;
    right: -15px;
    top: -5px;
    width: 25px;
    height: 25px;
    background-color: #a68c69;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lefeuve-nav-bar .navbar-toggler {
    background-color: #a68c69 !important;
}

.lefeuve-nav-bar .navbar-toggler:focus {
    outline: none;
    box-shadow: 0 0 0 #a68c69 !important;
}

.lefeuve-nav-bar .selected-link {
    border-bottom: 3px solid #a68c69;
}

.btn-panier-mobile {
    display: none;
}

@media (max-width: 713px) {
    .header-about-container p {
        font-size: 13px;
    }

    .header-about-container h4 {
        display: none;
    }

    .text__header {
        width: 90% !important;
        top: 30%;
    }



    .btn__content{
        top: 65%;
    }

    .btn__content p span {
        font-size: 12px;
        display: block;
    }


    .btn__content .header-whatsapp-content {
        padding: 5px;
        width: 225px;
    }

    .btn__paternariat {
        margin-top: 10px;
    }

    .carousel__img {
        height: 350px;
    }
}

@media (max-width: 413px) {
    .logo__img {
        width: 80px;
        height: 80px;
    }

    .btn__content{
        top: 55%;
    }

    .header-about-container{
        display: none;
    }

    .btn-panier-mobile .quantity-product {
        top: 0;
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 990px) {
    .lefeuve-nav-bar .selected-link {
        max-width: max-content;
    }

    .lefeuve-nav-bar .btn-panier {
        display: none;
    }

    .btn-panier-mobile {
        display: block;
        margin-left: -46px;
    }

    .btn .quantity-product {
        position: absolute;
        right: -22px;
    }
}
