.header-slider .swiper-slide {
    width: 100%;
}

.swiper-header-container{
    width: 100%;
    height: 80vh;
}

.carousel__img {
    object-fit: cover;
    aspect-ratio: 2/1;
}


.image-detail {
    height: 80vh;
    object-fit: contain;
}
