.loading-page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.loading-page-content .loading-img {
    width: 300px;
    height: 300px;
    background: url("/public/lefleuve-cg-logo.svg") center center no-repeat;
    background-size: cover;
}

.loading-page-text {
    margin-left: 5px;
}
