body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.whatsapp-content {
    background-color: #fff;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 4px solid #25d366;
    -webkit-box-shadow: 5px 5px 18px 5px rgba(0, 0, 0, 0.07);
    box-shadow: 5px 5px 18px 5px rgba(0, 0, 0, 0.07);
    animation: whatsapp-logo-float 1.5s ease-out 1s alternate infinite none running;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}

.whatsapp-modal-content {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 4px solid #25d366;
    -webkit-box-shadow: 5px 5px 18px 5px rgba(0, 0, 0, 0.07);
    box-shadow: 5px 5px 18px 5px rgba(0, 0, 0, 0.07);
    animation: whatsapp-logo-float 1.5s ease-out 1s alternate infinite none running;

    display: flex;
    justify-content: center;
    align-items: center;
}

.whatsapp-content a,
.whatsapp-modal-content a {
    text-decoration: none;
}

.whatsapp-content .my-icon-whatsapp {
    font-size: 40px;
    color: #25d366;
}

.whatsapp-modal-content .my-icon-whatsapp {
    font-size: 20px;
    color: #25d366;
}

.card-product-container .search-btn {
    background: black;
    border: 1px solid rgba(0, 0, 0, 0.23);
    color: #fff;
    padding: 15px 20px;
    border-radius: 0 4px 4px 0;
}

.card-product-container .inputSearch {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}

@keyframes whatsapp-logo-float {
    30% {
        transform: scale(0.3);
    }
    40% {
        transform: scale(0.4);
    }
    60% {
        transform: scale(0.6);
    }
    80% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}

@media (max-width: 713px) {
    .whatsapp-content {
        width: 60px;
        height: 60px;
    }

    .whatsapp-content .icon-whatsapp {
        font-size: 20px;
        color: #25d366;
    }
}
