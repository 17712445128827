.publicity-content {
    background-color: #58C3FF;
    overflow: hidden;
}

.publicity-content .pub-paragraph {
    overflow: hidden;
    white-space: nowrap;
}

.publicity-content .pub-paragraph > * {
    position: relative;
    animation: 10s linear 0s infinite alternate floatText;
}


@keyframes floatText {
    0%,
    25% {
        transform: translateX(0%);
        left: 00%;
    }
    75%,
    100% {
        transform: translateX(-100%);
        left: -100%;
    }
}
