.carousel__img {
    cursor: pointer;
}

.content-image-magnifier {
    max-width: 400px;
    max-height: 400px;
    object-fit: contain;
    margin: auto;
}

.content-image-magnifier .img-magnifier {
    object-fit: contain;
    aspect-ratio: 3/3;
    mix-blend-mode: color-burn;
}

.myHowIsWorkSwiper .sub-img-content {
    border: 2px solid #ddd;
    width: 70px;
    height: 70px;
    cursor: pointer;
}

.myHowIsWorkSwiper .sub-img-content .sub-img {
    object-fit: contain;
    margin: auto;
    width: 100%;
    height: 100%;
    aspect-ratio: 3;
    mix-blend-mode: color-burn;
}

.color-product {
    background: black;
    width: 40px;
    height: 40px;
    border: 3px solid #a68c69;
    border-radius: 50%;
}

.detail-sale {
    width: max-content;
}

.detail-product-content {
    position: relative;
    padding: 25px 0;
}

.detail-product-about {
    padding-top: 25px;
}

.detail-product-about h4 {
    margin-bottom: 25px;
}

.detail-product-price {
    margin-top: 50px;
}

.detail-product-content::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 60%;
    height: 1px;
    background: #ccc;
}

.btn-buy-content {
    margin-top: 50px;
}

.size-content .size-text {
    border: 1px solid #a68c69;
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-buy-content {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.btn-buy-content .btn-buy {
    background-color: #333;
    color: #fff;
    padding: 7px 50px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    border: none;
    width: 350px;
}

.product-detail-row {
    display: grid;
    grid-template-columns: 600px auto;
    gap: 40px;
}

.btn-buy-content .add-product {
    color: #a68c69;
    font-weight: bold;
}

@media (max-width: 713px) {
    .detail-product-price {
        margin-top: 10px;
    }

    .product-detail-row {
        display: block;
    }

    .detail-product-content::after {
        width: 100%;
    }

    .product-detail p:last-child {
        margin-bottom: 0;
    }

    .product-detail p {
        font-size: 14px;
    }
}

@media (max-width: 413px) {
    .product-detail p,
    .product-detail li {
        font-size: 14px;
    }

    .detail-product-price {
        margin-top: 50px;
    }

    .card-land-col p,
    .card-land-col h4 {
        font-size: 14px !important;
    }
}
