.not-found-content {
    width: 70%;
    margin: 70px auto;
    -webkit-box-shadow: 3px 9px 20px -3px rgba(0, 0, 0, 0.33);
    box-shadow: 3px 9px 20px -3px rgba(0, 0, 0, 0.33);
    text-align: center;
    padding: 20px;
}

.not-found-content .not-found-title {
    font-size: 6rem;
    color: #a68c69;
}

@media (max-width: 567px) {
    .not-found-content {
        width: 100%;
    }

    .not-found-content .not-found-title {
        font-size: 4rem;
    }
}
