:root {
    --clr-black: #333333;
}

.advantage__container .advantage__col {
    background-color: #ddd;
    padding-top: 15px;
    -webkit-box-shadow: 5px 5px 18px 5px rgba(0, 0, 0, 0.07);
    box-shadow: 5px 5px 18px 5px rgba(0, 0, 0, 0.07);
}

.discount-product {
    position: absolute;
    bottom: 5px;
    right: 10px;
}

.advantage__container .advantage__col h4 {
    color: #a68c69;
    margin-top: 10px;
    text-align: center;
    border-radius: 5px;
    position: relative;
}

.cut-text {
    --max-line: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-line);
}

.advantage__container .advantage__col h4:after {
    content: "";
    position: absolute;
    top: 5px;
    left: -20px;
    width: 25px;
    height: 15px;
    background-color: #a68c69;
}

.advantage__container h2,
.advantage__container p {
    color: var(--clr-black);
}

.advantage__container .advantage__col h4::before {
    content: "";
    position: absolute;
    top: 5px;
    right: -20px;
    width: 25px;
    height: 15px;
    background-color: #a68c69;
}

.brand-text {
    margin-bottom: 0;
}

img.publicity-img {
    width: 100%;
    height: 100%;
}

.top-house-link {
    color: #333333;
    text-decoration: none;
}

.top-house-link:hover {
    color: #333333;
    text-decoration: none;
}

.initial-price {
    display: block;
    margin-top: 5px;
    height: 12px;
}

.top-house-row .card-image {
    height: 400px;
    object-fit: contain !important;
    padding-top: 10px;
}

.more-article {
    width: max-content;
}

@media (max-width: 767px) {
    .small-text {
        font-size: 13px;
        margin-bottom: 10px;
    }

    .initial-price {
        font-size: 10px !important;
        display: block;
        margin-top: 5px;
        height: 12px;
    }

    .top-house-row > * {
        padding-right: 0 !important;
    }

    p.advantage__text {
        margin-bottom: 30px;
    }

    .top-house-row .card-image {
        height: 122px;
    }
}
