.similar-item-link {
    text-decoration: none;
    color: #333333;
}

.similar-item-link:hover {
    text-decoration: none;
    color: #333333;
}

.similar-item-link .similar-image, .top-house-link .card-image {
    height: 190px;
    object-fit: cover;
}

@media (max-width: 413px) {
    .similar-item-link .similar-image, .top-house-link .card-image {
        height: 150px;
    }
}

