.footer-container {
    background-color: #ddd;
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    margin: 0 -0.75rem !important;

}

.footer-container .footer__links {
    color: black;
    text-decoration: none;
}

.footer-container .partenariat__link {
    border: 1px solid #a68c69;
    border-radius: 8px;
    text-align: center;
    padding: .5rem;
}

.footer-container .footer-contact__link {
    color: black;
    text-decoration: none;
}

.footer-container .partenariat__content {
    margin-top: 1.5rem;
}

@media (max-width: 767px) {
    .footer-container {
        padding-right: 10px;
        padding-left: 10px;
    }

    .footer-container h4 {
        margin-top: 0;
    }

    .footer-container p {
        margin-bottom: 8px;
    }


    .footer-container a:not(:first-child) {
        font-size: 15px;
    }

    .footer-container .partenariat__content {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
}